import { useReducer, useMemo } from 'react';

type Action =
  | { type: 'callback'; cb: (arg: unknown) => unknown }
  | { type: 'update'; cb?: (arg: unknown) => { [key: string]: unknown }; payload?: { [key: string]: unknown } }
  | { type: 'reset'; payload: { [key: string]: unknown } };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer: React.Reducer<any, Action> = (state, action) => {
  switch (action.type) {
    case 'callback':
      return action.cb(state);
    case 'update':
      return action.cb
        ? {
            ...state,
            ...action.cb(state),
          }
        : {
            ...state,
            ...action.payload,
          };
    case 'reset':
      return action.payload;
    default:
      return state;
  }
};

interface IEmit<T> {
  cb: (cb: (prevState: T | unknown) => unknown) => void;
  update: (newState: { [key: string]: unknown }) => void;
  updateCb: (cb: (prevState: T | unknown) => { [key: string]: unknown }) => void;
  reset: (newState: { [key: string]: unknown }) => void;
}

export interface IStore<T> {
  state: T;
  emit: IEmit<T>;
  dispatch: React.Dispatch<Action>;
}

export default function useStore<T>(initialState: T): IStore<T> {
  const [state, dispatch] = useReducer(reducer, initialState);
  const emit: IEmit<T> = useMemo(
    () => ({
      cb: (cb) =>
        dispatch({
          type: 'callback',
          cb: (prevState) => cb(prevState),
        }),
      update: (newState) =>
        dispatch({
          type: 'update',
          payload: newState,
        }),
      updateCb: (cb) =>
        dispatch({
          type: 'update',
          cb: (prevState) => cb(prevState),
        }),
      reset: (newState) =>
        dispatch({
          type: 'reset',
          payload: newState,
        }),
    }),
    [],
  );
  return { state, dispatch, emit };
}
