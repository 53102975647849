const BLOG_SLUG = 'airsculpt-daily';
const AIRSCULPT_VIDEOS_SLUG = 'videos';
const NEWS_SLUG = 'news';
const GRECAPTCHA_V3_SITE_KEY = '6LcIv3ImAAAAAOHL9uAAl8MuROP2eeFbREnGAeoQ';
const TURNSTILE_SITEKEY = '0x4AAAAAAAF5_NDIrsRTTd0i';

const repeatedSectionsWhitelist = {
  DatoCmsSectionEbsIconsSlider: 2,
  DatoCmsSectionEbsParallaxSlider: 2,
  DatoCmsSectionEbsAirsculptDifference: 2,
  DatoCmsSectionEbsCustomerVideo: 3,
  DatoCmsSectionEbsCloser1: 2,
  DatoCmsSectionEbsGallery1: 2,
  DatoCmsSectionEbsCards1: 2,
  DatoCmsSectionEbsLocationSurgeonOrStaff: 2,
  DatoCmsSectionEbsLocationSurgeonOrStaff2: 3,
  DatoCmsSectionEbsFaq1: 4,
  DatoCmsSectionEbsHero1: 2,
  DatoCmsSectionEbsBlocks1: 6,
  DatoCmsSectionEbsFaq4: 4,
  DatoCmsSectionEbsBeforeAndAfter4: 2,
  DatoCmsSectionEbsBeforeAndAfter1: 3,
  DatoCmsSectionEbsHeroLp1: 3,
  DatoCmsSectionEbsProcedures3: 2,
  DatoCmsSectionEbsCards9: 2,
};

const LOCALES_QUERY = `{
  datoCmsSite {
    locales
  }
}`;

const INNER_PAGES_TEMPLATE = (localeToUse, allHeadersQuery, allFootersQuery) => `
query InnerPagesTemplateQuery {
  datoCmsInnerPagesTemplate(fallbackLocales: "en", locale: ${`"${localeToUse}"`}) {
    header {
      ${allHeadersQuery}
    }
    footer {
      ${allFootersQuery}
    }
    resourcesHero {
      ...on DatoCmsSectionEbsHeroImage1 {
        id
      }
    }
    resourcesCloser {
      ...on DatoCmsSectionEbsCloser5 {
        id
      }
    }
  }
}`;

const { ensureTrailingSlash } = require('./gatsby/utils');

function prefixBlogPostSlug(slug) {
  return ensureTrailingSlash(`/${BLOG_SLUG}/${slug}`);
}

function prefixVideosSlug(slug) {
  return ensureTrailingSlash(`/${AIRSCULPT_VIDEOS_SLUG}/${slug}`);
}

function prefixNewsSlug(slug) {
  return ensureTrailingSlash(`/${NEWS_SLUG}/${slug}`);
}

async function getAllDatoCmsLocales(graphql) {
  const datoCmsLocales = await graphql(LOCALES_QUERY);
  return datoCmsLocales.data.datoCmsSite.locales;
}

async function getACPData({ graphql, allHeadersQuery, allFootersQuery, localeToUse }) {
  const {
    data: {
      datoCmsInnerPagesTemplate: { header, footer, resourcesHero, resourcesCloser },
    },
  } = await graphql(INNER_PAGES_TEMPLATE(localeToUse, allHeadersQuery, allFootersQuery));
  const { slices, header: headerID, footer: footerID } = CONFIG.createSlicesObject({ header, footer }, localeToUse);
  return { slices, header: headerID, footer: footerID, resourcesHero, resourcesCloser };
}

function createSlicesObject(node, localeToUse) {
  let slices = {};
  let idHelper = '';
  idHelper = node.header?.id?.replace('DatoCmsSection', '');
  let headerID = [idHelper?.slice(0, idHelper?.indexOf('-')), idHelper?.slice(idHelper?.indexOf('-') + 1)];
  if (localeToUse) {
    headerID?.splice(1, 0, localeToUse.replace('-', '_'));
    headerID = headerID?.join('-');
  }
  const headerTypename = headerID?.slice(0, idHelper?.indexOf('-'));
  if (headerTypename) slices = { ...slices, [`${headerTypename}`]: headerID };

  idHelper = node.footer?.id?.replace('DatoCmsSection', '');
  let footerID = [idHelper?.slice(0, idHelper?.indexOf('-')), idHelper?.slice(idHelper?.indexOf('-') + 1)];
  if (localeToUse) {
    footerID?.splice(1, 0, localeToUse.replace('-', '_'));
    footerID = footerID?.join('-');
  }
  const footerTypename = footerID?.slice(0, idHelper?.indexOf('-'));
  if (!!footerTypename) slices = { ...slices, [`${footerTypename}`]: footerID };
  //test
  let sectionsIDs = [];
  const sectionsSlices = node.sections?.reduce((acc, curr) => {
    idHelper = curr?.id?.replace('DatoCmsSection', '');
    let sectionID = [idHelper?.slice(0, idHelper?.indexOf('-')), idHelper?.slice(idHelper?.indexOf('-') + 1)];
    if (localeToUse) {
      sectionID?.splice(1, 0, localeToUse.replace('-', '_'));
      sectionID = sectionID?.join('-');
    }
    const sectionTypename = sectionID?.slice(0, idHelper?.indexOf('-'));
    if (!!sectionTypename) {
      sectionsIDs.push(sectionID);
      return { ...acc, [`${sectionTypename}`]: sectionID };
    }
    return { ...acc };
  }, {});

  slices = { ...slices, ...sectionsSlices };

  return { slices, header: headerID, sections: sectionsIDs, footer: footerID };
}

function whichLocalesToSkip(locales) {
  // sample output `["en-CA","es", "fr-CA"]` for locale en
  if (process.env.GATSBY_LOCALE && process.env.GATSBY_SPANISH_PAGES === 'true') {
    return `[${locales
      .filter((locale) => !locale?.includes(process.env.GATSBY_LOCALE.split('-')[1])) // refactor to work with both langs and locations
      .map((locale) => `"${locale}"`)
      .join(', ')}]`;
  } else if (process.env.GATSBY_LOCALE) {
    return `[${locales
      .filter((locale) => locale !== process.env.GATSBY_LOCALE) // refactor to work with both langs and locations
      .map((locale) => `"${locale}"`)
      .join(', ')}]`;
  } else {
    return `[${locales
      .filter((locale) => {
        if (process.env.GATSBY_SPANISH_PAGES === 'true') {
          return locale?.includes('-');
        } else {
          return locale !== 'en';
        }
      })
      .map((locale) => `"${locale}"`)
      .join(', ')}]`;
  }
}

function getPageNode(nodeData, allNodes) {
  const __typename = nodeData?.__typename;
  const id = nodeData?.id;
  const component = allNodes?.data?.[`all${__typename}`]?.edges.filter(({ node }) => {
    return node.id === id;
  })[0];

  return component;
}

function wichLocalesToUse(locales) {
  // sample output ["en", "es"] for locale en

  if (process.env.GATSBY_LOCALE && process.env.GATSBY_SPANISH_PAGES === 'true') {
    return locales.filter((locale) => locale?.includes(process.env.GATSBY_LOCALE.split('-')[1]));
  } else if (process.env.GATSBY_LOCALE) {
    return locales.filter((locale) => locale === process.env.GATSBY_LOCALE);
  } else {
    return locales.filter((locale) => {
      if (process.env.GATSBY_SPANISH_PAGES === 'true') {
        return !locale?.includes('-');
      } else {
        return locale === 'en';
      }
    });
  }
}

function localeSlugPrefix(locale) {
  if (!locale) return '';
  if (process.env.GATSBY_LOCALE) {
    const localeLang = locale.split('-')[0];
    return process.env.GATSBY_LOCALE?.includes(localeLang) ? '' : `/${localeLang}`;
  } else {
    return locale !== 'en' ? `/${locale}` : '';
  }
}

function SITE() {
  if (process.env.GATSBY_LANDING_PAGES === 'true') {
    return 'LPS';
  } else if (process.env.GATSBY_LOCALE == 'en-CA') {
    return 'CANADA';
  } else if (process.env.GATSBY_LOCALE == 'en-UK') {
    return 'UK';
  } else {
    return 'USA';
  }
}

export const CONFIG = {
  AIRSCULPT_VIDEOS_SLUG: 'videos',
  VIDEOS_NAME: 'Videos',
  AIRSCULPT_VIDEOS_NAME: 'Airsculpt TV',
  BLOG_SLUG,
  NEWS_SLUG,
  GRECAPTCHA_V3_SITE_KEY,
  AIRSCULPT_DAILY_NAME: 'AirSculpt Daily',
  TURNSTILE_SITEKEY,
  prefixBlogPostSlug,
  prefixVideosSlug,
  prefixNewsSlug,
  getAllDatoCmsLocales,
  whichLocalesToSkip,
  wichLocalesToUse,
  SITE,
  localeSlugPrefix,
  getPageNode,
  createSlicesObject,
  getACPData,
  repeatedSectionsWhitelist,
};
