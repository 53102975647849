import React, { FunctionComponent, useEffect, useState } from 'react';
import intercom from 'intercom';
import useGlobal from '@/hooks/useGlobal';
import { isUTM } from '@/utils';

interface ILayoutProps {
  children?: Array<React.ReactNode | null | undefined>;
  location: Location;
  path?: string;
}

const Layout: FunctionComponent<ILayoutProps> = function ({ children, path, ...props }) {
  const [initialized, setInitialized] = useState(false);
  const global = useGlobal();

  useEffect(() => {
    const timeValue = setInterval(() => {
      if (document.querySelector('.big-play-toggle')) {
        const elements = document.querySelectorAll('.big-play-toggle');
        elements.forEach((e) => e.parentElement?.removeChild(e));
        clearInterval(timeValue);
      }
    }, 10);
  }, []);

  useEffect(() => {
    try {
      if (!initialized) {
        intercom.init(); // searches for userdata on cookies and performs intercom boot or update.
        setInitialized(true);
      }
      setTimeout(() => {
        intercom.initializeBotTriggers(); // pairs react buttons with intercom hidden triggers so we easily dispatch intercom bot flows from buttons
      }, 100);
    } catch (error) {
      console.log(`[intercom] ${error instanceof Error ? error.message : 'Unknown Error'}`);
    }
    global?.emit.update({ entry_point: window.location.href }); //JM wanted to know what's the first url the user hits.
  }, [path]);

  useEffect(() => {
    try {
      if (!window.localStorage.entry_point || isUTM(window.location.search)) {
        window.localStorage.setItem('entry_point', window.location.href);
      }
    } catch (error) {}
  }, []);
  return (
    <>
      {/* <EbsArrowScrollTop /> */}
      {intercom.botTriggers.map((trigger) => {
        // DONT EVER DELETE THIS BUTTONS, UNLESS YOU WANT TO REMOVE INTERCOM
        return (
          <button
            key={`button-${trigger}`}
            id={trigger}
            aria-hidden="true"
            aria-label={trigger}
            tabIndex={-1}
            style={{
              opacity: 0,
              width: '1px',
              height: '1px',
              position: 'fixed',
              left: 0,
              top: 0,
              pointerEvents: 'none',
            }}
          />
        );
      })}
      {children}
    </>
  );
};

export default Layout;
