import { FormValues } from '@/components/shared/forms/lead-form';
import { IMetaTags } from '@/types';
import { CONFIG } from 'config/config';
import React from 'react';
import { FormState } from 'react-hook-form';

interface IFormSubmit {
  values: Record<string, string>;
  setStatus: (args: any) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  postUrl?: string | null;
  redirectUrl?: string | null;
  successMessage?: string | null;
  errorMessage?: string | null;
  post?: boolean | null;
}

const formikSubmit: (args: IFormSubmit) => Promise<void> = async function formSubmit({
  values,
  setStatus,
  setSubmitting,
  postUrl,
  redirectUrl,
  successMessage,
  errorMessage,
  post,
}) {
  setStatus({});
  if (postUrl) {
    try {
      const formValues = {
        ...values,
        page: window.location.href,
        date: `${new Date()}`,
      };
      const res = await fetch(`${postUrl}`, {
        method: post ? 'POST' : 'GET',
        body: post ? JSON.stringify(formValues) : undefined,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (!res.ok && errorMessage) {
        throw new Error(errorMessage);
      }
      if (redirectUrl) {
        window.location.pathname = redirectUrl;
      } else {
        setStatus({
          type: 'success',
          message: successMessage || 'Success!. Your submission has been sent!.',
        });
      }
    } catch (error) {
      console.log(error?.message);
      setStatus({
        type: 'error',
        message: errorMessage || 'There was an error submitting your form, please try again in a few moments.',
      });
      throw new Error(error?.message);
    }
  } else {
    setStatus({
      type: 'error',
      message: '[Dev error]: there was no POST URL provided',
    });
  }
  setSubmitting(false);
};

function checkLinkTarget(link?: string | false | null): string | boolean {
  const match1 = link && link.match(/http(s{0,1})/gi);
  const match2 = link && link.match(/tel:|mailto:/gi);
  const isExternal = (match1 && match1[0]) || (match2 && match2[0]) || false;
  return isExternal;
}

function updateQueryStringParameter(uri, key, value) {
  let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  let separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

function getQueryParam(name: string, url?: string): string | null {
  try {
    if (typeof window !== 'undefined' && !url) url = window.location.href;
    if (typeof window === 'undefined') url = '';
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } catch (error) {
    console.log(error.message);
    return '';
  }
}

export interface Seo {
  tags: {
    tagName: string;
    content: string;
  }[];
}

type SeoInput = Seo | null | undefined;

function appendToTitleTag(string: string, seo?: IMetaTags): IMetaTags | undefined {
  const titleTag = seo?.tags?.find((x) => x.tagName == 'title');
  if (titleTag) {
    const newTitleTag: { tagName: 'title'; content: string } = {
      tagName: 'title',
      content: titleTag.content + string,
    };
    const tags = (seo && [...(seo?.tags || [])]) || [];
    tags[0] = newTitleTag;
    return { ...seo, tags };
  } else {
    return seo;
  }
}

const formatPhone = (phone: string) => {
  const formatedPhone = phone.replace(/\D/g, '');
  const length = formatedPhone.length;
  if (length === 10) {
    return `+1${formatedPhone}`;
  } else if (length > 10) {
    return `+${formatedPhone}`;
  }
  return '';
};

const isUTM = (search: string) => {
  const params = new URLSearchParams(search);
  const isUTM =
    params.has('utm_source') ||
    params.has('utm_campaign') ||
    params.has('utm_content') ||
    params.has('utm_medium') ||
    params.has('utm_term');
  return isUTM;
};

const getInternationalPhone = (phone: string) => {
  if (phone) {
    let formatedPhone = phone.replace(/\D/g, '');
    const length = formatedPhone.length;
    if (length === 10) {
      return `+1${formatedPhone}`;
    } else if (length > 10) {
      if (formatedPhone.startsWith('0', 0)) {
        while (formatedPhone.startsWith('0', 0)) {
          formatedPhone = formatedPhone.slice(1);
        }
        if (formatedPhone.length > 10) {
          if (formatedPhone.length > 11 && formatedPhone.startsWith('1')) {
            return `+${formatedPhone.slice(1)}`;
          } else {
            return `+${formatedPhone}`;
          }
        } else if (formatedPhone.length === 10) {
          return `+1${formatedPhone}`;
        } else {
          return phone;
        }
      } else if (formatedPhone.length > 11 && formatedPhone.startsWith('1')) {
        return `+${formatedPhone.slice(1)}`;
      } else {
        return `+${formatedPhone}`;
      }
    }
  }
  return phone;
};

/**
 * Fetches country list with zip code params from the static folder, as a way to improve performance for US visitors.
 * @param callback React set state
 * @returns Promise
 */
function getCountryList(callback?: React.Dispatch<React.SetStateAction<any>>) {
  return fetch('/jsons/postal-validation.json')
    .then((x) => x.json())
    .then((x) => {
      if (callback) {
        callback(x);
      } else {
        return x;
      }
    })
    .catch((error) => {
      throw error;
    });
}

/**
 *
 * @param
 * @returns LP ecosystem slug from a regular slug
 */
function toLpEcosystemSlug({ slug, locale, tpv, tg }: { slug: string; locale: string; tpv: string; tg: boolean }) {
  const slugAttributes = slug
    .split('/')
    .filter((x) => x)
    .filter((att) => att !== 'lp' && att !== 'tg')
    .filter((att) => att !== tpv); //filter out tpv too
  const slugLocale = CONFIG.localeSlugPrefix(locale);

  const ecoSlug = `/${slugLocale}/${tg ? 'tg' : 'lp'}/${tpv}/${slugAttributes
    .filter((att) => att !== locale)
    .join('/')}/`.replace(/\/\//g, '/');
  return ecoSlug;
}

/**
 *
 * @param
 * @returns Ecosystem slug from a regular slug
 */
function toEcosystemSlug({ slug, locale }: { slug: string; locale: string }) {
  const slugAttributes = slug.split('/').filter((x) => x);
  const slugLocale = CONFIG.localeSlugPrefix(locale);

  const ecoSlug = `/${slugLocale}/${slugAttributes.filter((att) => att !== locale).join('/')}`.replace(/\/\//g, '/');
  return ecoSlug;
}

/**
 *
 * @param {boolean} condition to either skip a test or not...
 * @returns
 */
const itif = (condition: boolean) => (condition ? it : it.skip);

const setVideoInUrl = (videoID: string) => {
  try {
    const params = new URLSearchParams(window.location.search);
    if (videoID) {
      params.set('video', videoID);
    } else {
      params.delete('video');
    }
    const new_search = params.toString() ? '?' + params.toString() : '';
    window.history.replaceState('', '', `${window.location.pathname}${new_search}`);
  } catch (error) {}
};

const figureOutAreasOfInterestPrefill = (areasOfInterest: string[]) => {
  return areasOfInterest
    .map((area) => {
      switch (area) {
        case 'Back':
          return 'Back / Bra Roll';
        case 'Chin':
          return 'Chin / Jowls / Neck';
        case 'Stomach':
          return 'Lower Abdomen';
        case 'Gynecomastia':
        case 'Male Chest':
          return 'Gynecomastia / Male Chest';
        case 'Legs':
          return 'Calves';
        case 'Hip Flip':
          return 'Hip Flip (Hips)';
        case 'BBL':
          return 'Power BBL (Buttocks)';
        // We don't want to prefill these areas of interest, so we return an empty string and then filter them out
        case 'Corrective':
        case 'Other':
          return '';
        default:
          return area;
      }
    })
    .filter((area) => area !== '');
};

function extractStringWithEmTag(input: string, maxLength: number) {
  let emTagRegex = /<em>(.*?)<\/em>/;
  let match = input.match(emTagRegex);
  let output = '';

  function removeHtmlTags(str: string) {
    return str.replace(/<[^>]*>/g, '');
  }

  function appendEllipsis(str: string, endIndex: number) {
    while (str[endIndex] !== ' ' && endIndex < str.length) {
      endIndex++;
    }
    return str.slice(0, endIndex).trim() + '...';
  }

  if (match) {
    let emTagContent = match[0];
    let emTagIndex = input.indexOf(emTagContent);

    let startIndex = emTagIndex - Math.floor((maxLength - emTagContent.length) / 2);
    let prependEllipsis = false;
    let appendEllipsis = false;

    if (startIndex < 0) {
      startIndex = 0;
    } else {
      prependEllipsis = true;
    }

    let endIndex = startIndex + maxLength;
    if (endIndex > input.length) {
      endIndex = input.length;
      startIndex = endIndex - maxLength;
      if (startIndex < 0) {
        startIndex = 0;
      }
    } else {
      appendEllipsis = true;
    }

    if (prependEllipsis) {
      while (input[startIndex] !== ' ' && startIndex > 0) {
        startIndex--;
      }
      output = '...' + input.slice(startIndex + 1, endIndex);
    } else {
      output = input.slice(startIndex, endIndex);
    }

    if (appendEllipsis) {
      while (input[endIndex] !== ' ' && endIndex < input.length) {
        endIndex++;
      }
      output = output.slice(0, endIndex - startIndex).trim() + '...';
    }
  } else {
    let visibleText = removeHtmlTags(input);
    if (maxLength < visibleText.length) {
      let tempOutput = visibleText.slice(0, maxLength);
      let lastSpaceIndex = tempOutput.lastIndexOf(' ');
      tempOutput = tempOutput.slice(0, lastSpaceIndex).trim();

      let lastIndex = input.indexOf(tempOutput) + tempOutput.length;
      output = input.slice(0, lastIndex);
      output = appendEllipsis(output, lastIndex);
    } else {
      output = input;
    }
  }

  return output;
}

const determinePhonePrefill = (phone: string): string => {
  if (!phone) return '';

  const phoneNumber = {
    countryCode: phone?.split('')?.reverse()?.slice(10)?.reverse()?.join('')?.replace(/\+/, ''),
    areaCode: phone?.split('')?.reverse()?.slice(7, 10)?.reverse()?.join('') || '',
    phoneNumber: phone?.split('')?.reverse()?.slice(0, 7)?.reverse()?.join('') || '',
  };

  let dropdownPrefill = '&phoneCountrySelect=';
  let phonePrefill = '';
  let parsedAreaCode = phoneNumber.areaCode;

  switch (phoneNumber.countryCode) {
    case '1':
      dropdownPrefill += 'US';
      phonePrefill = `&nationalPhoneNumber=${phoneNumber.areaCode}${phoneNumber.phoneNumber}`;
      break;
    case '44':
    case '440':
      dropdownPrefill += 'UK';
      // Remove leading 0 for area code if needed
      if (parsedAreaCode.startsWith('0')) {
        parsedAreaCode = parsedAreaCode.slice(1);
      }
      phonePrefill = `&ukNationalPhone=${parsedAreaCode}${phoneNumber.phoneNumber}`;
      break;
    default:
      dropdownPrefill += 'Other';
      phonePrefill = `&phoneNumber[country]=${phoneNumber.countryCode}&phoneNumber[area]=${phoneNumber.areaCode}&phoneNumber[phone]=${phoneNumber.phoneNumber}`;
      break;
  }

  return dropdownPrefill + phonePrefill;
};

const formatFormState = (formState: FormState<FormValues>) => {
  const errorKeys = Object.keys(formState.errors) as (keyof FormValues)[];

  type CustomError = {
    [key in keyof FormValues]?: {
      message?: string;
      type?: string;
    };
  };

  let errors: CustomError = {};
  errorKeys.forEach((key) => {
    errors = {
      ...errors,
      [key]: {
        message: formState.errors[key]?.message,
        type: formState.errors[key]?.message,
      },
    };
  });

  return {
    isDirty: formState.isDirty,
    isLoading: formState.isLoading,
    isSubmitted: formState.isSubmitted,
    isSubmitSuccessful: formState.isSubmitSuccessful,
    isSubmitting: formState.isSubmitting,
    isValidating: formState.isValidating,
    isValid: formState.isValid,
    disabled: formState.disabled,
    submitCount: formState.submitCount,
    defaultValues: formState.defaultValues,
    dirtyFields: formState.dirtyFields,
    touchedFields: formState.touchedFields,
    errors,
  };
};

const getFormIntentSessionId = () => {
  const sessionId = localStorage.getItem('formIntentSessionId');
  if (sessionId) {
    return sessionId;
  }
  const LENGTH = 32;
  const _bytes = new Uint8Array(LENGTH / 2);
  window.crypto.getRandomValues(_bytes);
  const newSessionId = Array.from(_bytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
  localStorage.setItem('formIntentSessionId', newSessionId);
  return newSessionId;
};

const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const toRadians = (degree: number): number => degree * (Math.PI / 180);

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers

  return distance;
};

const getEnv = (): 'dev' | 'prd' => {
  try {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.get('dev') === 'true' || url.host === 'dev.airsculpt.com' || url.host === 'dev--airsculpt.netlify.app') {
      return 'dev';
    }
  } catch (error) {
    console.error(error);
  }
  return 'prd';
};

export {
  itif,
  formikSubmit,
  formatFormState,
  toLpEcosystemSlug,
  checkLinkTarget,
  getQueryParam,
  appendToTitleTag,
  formatPhone,
  getEnv,
  isUTM,
  getInternationalPhone,
  getCountryList,
  setVideoInUrl,
  updateQueryStringParameter,
  figureOutAreasOfInterestPrefill,
  extractStringWithEmTag,
  toEcosystemSlug,
  determinePhonePrefill,
  getFormIntentSessionId,
  calculateDistance,
};
