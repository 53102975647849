module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-helmet-async@1.2.3_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__131f5460724dfba124763f8343f7f48c/node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__es_bc65d105e5a1ff0a010adb810ce2942d/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://airsculpt.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__esbuild_d0d0d20068ccb1f1f0e91a6d1eda116d/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ead486","showSpinner":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__esbuild@0.17.19_react-dom@18.3.1_react_6750c00bbe264e0f1a05645ed38f96bd/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
