import React, { createContext, useCallback, ReactElement, FunctionComponent, useEffect } from 'react';
import useStore, { IStore } from 'hooks/useStore';
import { scrollto } from 'bit/shared/utils';
import { IPageContext } from '@/types';

const INITIAL_GLOBAL_STATE = {
  //must be an object
  modal: false,
  ctx: {} as IPageContext,
  popup: '',
  entry_point: '',
  hideCookiesNotice: false,
};

export const globalContext = createContext<IStore<typeof INITIAL_GLOBAL_STATE> | null>(null);

const { Provider } = globalContext;

interface IGlobalProvider {
  children: ReactElement;
}

const GlobalProvider: FunctionComponent<IGlobalProvider> = ({ children }) => {
  const store = useStore(INITIAL_GLOBAL_STATE);
  const dispatch = useCallback((arg) => store.dispatch(arg), []);
  useEffect(() => {
    try {
      if (window.location.hash) {
        setTimeout(() => {
          requestAnimationFrame(() => scrollto(`${window.location.hash.replace('#', '')}`));
        }, 500);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (store.state.modal == true) {
      try {
        CallTrk.swap();
        console.log('Callrail swap executed');
      } catch (error) {
        console.log("Couldn't execute Callrail swap");
      }
    } else {
      try {
        if (store.state?.popup) {
          const popupIndex = store.state?.popup?.popupIndex;
          const popupId = store.state?.popup?.popupId;
          if (popupId && typeof popupIndex === 'number') {
            setTimeout(() => {
              const focusElement = document.querySelectorAll(`[data-popup-id="${popupId}"]`)[popupIndex] as HTMLElement;
              focusElement?.focus();
            }, 200);
          }
        }
      } catch (error) {
        console.log('ERROR CAPTURING FOCUS', error);
      }
    }
  }, [store.state.modal]);

  return <Provider value={{ state: store.state, dispatch, emit: store.emit }}>{children}</Provider>;
};

export default GlobalProvider;
