export const botTriggers = [
  // 'callintent',
  'contact-flow',
  // 'book-consult',
  // 'thankyou-intercomform1',
  // 'callintent-houston',
  // 'callintent-atlanta',
  // 'callintent-orlando',
  // 'callintent-miami',
  // 'callintent-san-diego',
  // 'callintent-phoenix',
  // 'callintent-minneapolis',
  // 'callintent-denver',
  // 'callintent-dc',
  // 'callintent-toronto',
  // 'callintent-beverly-hills',
  // 'callintent-sacramento',
  // 'callintent-seattle',
  // 'callintent-dallas',
  // 'callintent-new-york-city',
  // 'callintent-nashville',
  // 'callintent-chicago',
  // 'callintent-charlotte',
  // 'callintent-salt-lake-city',
];

export function init() {
  window.cookiesInterval = setInterval(() => {
    if (window.Intercom) {
      clearInterval(window.cookiesInterval);
      window.Intercom('boot', {
        app_id: process.env.GATSBY_INTERCOM_APP_ID,
      });
    }
  }, 500);
}

export function initializeBotTriggers() {
  document.querySelectorAll('[data-intercom-action]').forEach((cta) => {
    window[`${cta.dataset.intercomAction}-flag`] = false;

    function dispatchCTA() {
      if (!window[`${cta.dataset.intercomAction}-flag`]) {
        window[`${cta.dataset.intercomAction}-flag`] = true;
        document.querySelector(`#${cta.dataset.intercomAction}`).click();
        console.log(cta.dataset.intercomAction, 'clicked');
        const pollInterval = window[`${cta.dataset.intercomAction}-interval`];
        if (pollInterval) clearInterval(pollInterval);
      } else window.Intercom('show');
    }

    function triggerIntercomCTA() {
      if (window.Intercom && window.Intercom.booted) {
        if (window[`${cta.dataset.intercomAction}-interval`]) {
          setTimeout(dispatchCTA, 750);
        } else dispatchCTA();
      } else if (!window[`${cta.dataset.intercomAction}-interval`]) {
        window[`${cta.dataset.intercomAction}-interval`] = setInterval(triggerIntercomCTA, 500);
      }
    }
    cta.addEventListener('click', triggerIntercomCTA);
  });
}

export default {
  init,
  botTriggers,
  initializeBotTriggers,
};
