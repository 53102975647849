import { stringify } from 'query-string';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; //TODO: Add testing for this

function generateQueries(queries: { [key: string]: unknown }): string {
  let toWrite = `import { graphql } from 'gatsby'\n\n`;
  for (const [queryName, queryString] of Object.entries(queries)) {
    toWrite += `export const ${queryName} = graphql\`${queryString}\`;\n`;
  }
  return toWrite;
}

interface IFormSubmit {
  values: Record<string, string>;
  setStatus: (args: any) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  postUrl?: string | null;
  redirectUrl?: string | null;
  successMessage?: string | null;
  errorMessage?: string | null;
  post?: boolean | null;
}

const formikSubmit: (ards: IFormSubmit) => Promise<Response | undefined> | never = async function formSubmit({
  values,
  setStatus,
  setSubmitting,
  postUrl,
  redirectUrl,
  successMessage,
  errorMessage,
  post,
}) {
  setStatus({});
  if (postUrl) {
    try {
      const formValues = {
        ...values,
        page: window.location.href,
        date: `${new Date()}`,
      };
      const res = await fetch(`${postUrl}?${stringify(formValues)}`, {
        method: post ? 'POST' : 'GET',
        body: post ? JSON.stringify(formValues) : undefined,
      });
      if (!res.ok) {
        throw new Error(errorMessage);
      }
      if (redirectUrl) {
        window.location.pathname = redirectUrl;
      } else {
        setStatus({
          type: 'success',
          message: successMessage ?? 'Success!. Your submission has been sent!.',
        });
        return res;
      }
    } catch (error) {
      console.log(error?.message);
      setStatus({
        type: 'error',
        message: errorMessage ?? 'There was an error submitting your form, please try again in a few moments.',
      });
    }
  } else {
    setStatus({
      type: 'error',
      message: '[Dev error]: there was no POST URL provided',
    });
  }
  setSubmitting(false);
};

function scrollto(elementID: string): void {
  const to = (document.getElementById(elementID) as HTMLElement)?.offsetTop - 60;
  window.scrollTo({ top: to, behavior: 'smooth' });
}

function ensureTrailingSlash(slug: string): string {
  return /\/$/.test(slug) ? slug : `${slug}/`;
}

export { generateQueries, formikSubmit, scrollto, ensureTrailingSlash };
