import React from 'react';
import GlobalProvider from 'context/global';
import Layout from './src/layout';
// styles
import './styles/main.scss';

export const wrapRootElement = ({ element }) => {
  return (
      <GlobalProvider>{element}</GlobalProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate = (state) => {
  if (process.env.GATSBY_LANDING_PAGES) {
    if (window.gtag && window.getUAForDomain) {
      window.gtag('config', window.getUAForDomain());
    }
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }
  if (window.CallTrk) {
    CallTrk.swap();
  }
};
